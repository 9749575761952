<template>
  <Page empty :icon="noInfo" class="bg-white">
    <!-- 导航栏 -->
    <NavBar title="" customClickLeft @clickLeft="handleClickLeft"></NavBar>

    <Empty :image="noInfo" imgWidth="0.84rem" imgHeight="0.78rem" description="Not found page"></Empty>
  </Page>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import NavBar from '@/components/NavBar/index';
import Empty from '@components/Empty/index.vue';
import UA from '@/client/ua';
import { useRouter } from '@/utils/compsitionHack';
import ClinentApp from '@/client/index';

export default defineComponent({
  components: {
    Empty,
    NavBar
  },
  setup() {
    const router = useRouter();

    function handleClickLeft() {
      if (UA.androidClient) {
        ClinentApp.finish();
      } else {
        router.back();
      }
    }
    return {
      noInfo: require('@/assets/404.jpg'),
      handleClickLeft
    };
  }
});
</script>
<style></style>
